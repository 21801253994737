/*
 * 最低限必要なjs
*/
const $ = jQuery
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './partials/imagesloaded.pkgd.min'
import './partials/slick.min'

import './partials/moovoo/lib/lib.js'
import './partials/moovoo/common.js'

import './partials/auth'
import lazyLoadImage  from './partials/lazyload-image'

$(document).ready(function () {
  lazyLoadImage.initModule();
});

$(function () {
  // #で始まるリンクをクリックしたら実行されます
  $('a[href^="#"]').click(function (e) {
    const href = $(this).attr("href");
    const target = $(href === "#" || href === "" ? 'html' : href);
    const position = target.offset().top;

    //　追加読み込み
    $.when(
      $("html, body").animate({
        scrollTop: position
      }, 400, "swing"),
      e.preventDefault(),
    ).done(function() {
      const diff = target.offset().top;
      if (diff === position) {
      } else {
        $("html, body").animate({
          scrollTop: diff
        }, 10, "swing");
      }
    });
  });
});